.lia-list {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.lia-pager.lia-pager {
  display: flex;
  align-items: center;
  padding: 0 5px;
  color: var(--lia-tag-color);
  background-color: var(--lia-tag-bg-color);
  border-radius: var(--lia-tag-border-radius);

  &:hover,
  &:focus {
    color: var(--lia-tag-hover-color);
    background-color: var(--lia-tag-bg-hover-color);

    .lia-pager-icon {
      fill: var(--lia-tag-hover-color);
    }
  }

  .lia-pager-icon {
    fill: var(--lia-tag-color);
  }
}
